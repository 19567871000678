// about page small slider
.small-text-slider--container {
	max-width: 375px;
	margin: auto;
}

.small-text-slider {
	padding: 25px 50px 75px 50px;

	.flickity-page-dots {
		bottom: 50px;
		left: 0;

		.dot {
			width: 12px;
			height: 12px;
			background-color: $medium-grey;
		}
	}
}

.small-text-slider--content {
	width: 100%;
	text-align: center;
	margin-right: 1rem;
	margin-bottom: 1.5rem;

	h2 {
		color: $gold;
		font-size: 8rem;
		line-height: 1;
	}
}

.small-text-slider--numbers {
	list-style: none;
	padding: 0;
	margin: auto;
	width: 80%;
	display: flex;
	justify-content: center;
	@include font-family--futura;
	font-weight: 500;
	text-align: center;
	color: lighten($medium-grey, 3%);

	li {
		cursor: pointer;
		flex: 1 0%;
		padding: 5px;
		margin: 0 5px;
		transition: color .3s;

		&.is-selected {
			color: $teal;
		}
	}
}