.gallery {

    .gridContainer {
        margin-bottom: $gutter*6;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
        grid-gap: 1.5rem;
        @include media ($small) {
            grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        }
        .grid-item {
            display: none;
            &.show {display: block;}
            position: relative;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &::before {
            content:'';
            position: absolute;
            width: 500px;
            height: 700px;
            background: url('../images/topography.svg');
            background-repeat: no-repeat;
            transform: rotate(295deg);
            z-index: -1;
            left: -11%;
            top: -20%;
            
        }

        .play-button {
            position: absolute;
            top: calc(50% - 50px);
            left: calc(50% - 50px);
            width: 100px;
            &:hover {
                cursor: pointer;
            }
            svg {
                fill: $white;
                opacity: 1;
                transition-property: transform, opacity;
                transition-duration: 0.2s;
                &:hover {
                    transform: translateY(-2px);
                    opacity: 0.8;
                    transition-property: transform, opacity;
                    transition-duration: 0.2s;
                }
            }
        }
    
    }

}