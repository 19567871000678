.flex {
    display: flex;
    flex-flow: row wrap;

    margin: {
        left: -$gutter;
        right: -$gutter;
    }

    @include media($medium) {
        margin: {
            left: 0;
            right: 0;
        }
    }

    // @include media($medium) {
    //     flex-wrap: wrap;
    // }

    > div {
        flex: 1;
        margin-bottom: 2em;
        padding: {
            left: $gutter;
            right: $gutter;
        }

        @include media($medium) {
            flex: none;
            width: 100%;

            padding: {
                left: 0;
                right: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.isVerticallyCentered {
        align-items: center;
    }

    &.isGutterless {
        margin: 0;

        > div {
            padding: 0;
        }
    }
}

// //auto flex
// [class^="flex-"] {
//     flex: 1 1 auto;
// }

// // flex-10
// @each $breakpoint in map-keys($grid-breakpoints) {
//     $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

//     .flex#{$infix}-auto {
//         @include media(map-get($grid-breakpoints, $breakpoint)) {
//             flex: 1 1 auto;
//         }
//     }

//     .flex#{$infix}-10 {
//         @include media(map-get($grid-breakpoints, $breakpoint)) {
//             @include flex-percentage(10);
//         }
//     }

//     .flex#{$infix}-20 {
//         @include media(map-get($grid-breakpoints, $breakpoint)) {
//             @include flex-percentage(20);
//         }
//     }

//     .flex#{$infix}-25 {
//         @include media(map-get($grid-breakpoints, $breakpoint)) {
//             @include flex-percentage(25);
//         }
//     }

//     .flex#{$infix}-30 {
//         @include media(map-get($grid-breakpoints, $breakpoint)) {
//             @include flex-percentage(30);
//         }
//     }

//     .flex#{$infix}-33 {
//         @include media(map-get($grid-breakpoints, $breakpoint)) {
//             @include flex-percentage(100 / 3);
//         }
//     }

//     .flex#{$infix}-40 {
//         @include media(map-get($grid-breakpoints, $breakpoint)) {
//             @include flex-percentage(40);
//         }
//     }

//     .flex#{$infix}-50 {
//         @include media(map-get($grid-breakpoints, $breakpoint)) {
//             @include flex-percentage(50);
//         }
//     }

//     .flex#{$infix}-60 {
//         @include media(map-get($grid-breakpoints, $breakpoint)) {
//             @include flex-percentage(60);
//         }
//     }

//     .flex#{$infix}-66 {
//         @include media(map-get($grid-breakpoints, $breakpoint)) {
//             @include flex-percentage(100 / 1.5);
//         }
//     }

//     .flex#{$infix}-70 {
//         @include media(map-get($grid-breakpoints, $breakpoint)) {
//             @include flex-percentage(70);
//         }
//     }

//     .flex#{$infix}-80 {
//         @include media(map-get($grid-breakpoints, $breakpoint)) {
//             @include flex-percentage(80);
//         }
//     }

//     .flex#{$infix}-90 {
//         @include media(map-get($grid-breakpoints, $breakpoint)) {
//             @include flex-percentage(90);
//         }
//     }

//     .flex#{$infix}-100 {
//         @include media(map-get($grid-breakpoints, $breakpoint)) {
//             @include flex-percentage(100);
//         }
//     }
// }


// .columns {
//     display: flex;
//     margin: {
//         left: -$column-gap;
//         right: -$column-gap;
//     }

    // @include media($large) {
    //     margin: {
    //         left: -1.5rem;
    //         right: -1.5rem;
    //     }
    // }

    // @include media($medium) {
    //     flex-wrap: wrap;
    // }

    // &.alignCenter {
    //     align-items: center;
    // }

    // .column {
    //     display: block;
    //     flex-basis: 0;
    //     flex-grow: 1;
    //     flex-shrink: 1;
    //     padding: $column-gap;

        // @include media($large) {
        //     padding: 1.5rem;
        // }

        // @include media($medium) {
        //     flex: none;
        //     width: 100%;
        // }

        // &.is-two-thirds {
        //     width: 66.666%;
        //     flex: none;

        //     @include media($medium) {
        //         width: 100%;
        //     }
        // }
//     }
// }
