.about-page {

	// #hero.page-header .page-header--icon {
	// 	height: 100%;
    //     transform: translateY(10%);
	// }

	#hero.page-header {

		svg {
            height: 100%;
            width: auto;
            position: absolute;
            bottom: 0;
            left: 0;

            @include media($medium) {
                width: 100%;
                height: auto;
            }
        }
	}

}
