@mixin gradient-background($direction, $color1, $color2) {
    position: relative;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      top: 0; left: 0;
      display: block;
      width: 100%; height: 100%;
      z-index: -1;
      background: linear-gradient($direction, $color1, $color2);
    }
  }
