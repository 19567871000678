// Tab icon
.tab-icon {
	width: 85px;
	height: 85px;
	transition: transform .2s ease-in-out;

	@include media($large) {
		width: 70px;
		height: 70px;
	}

	@include media($medium) {
		width: 50px;
		height: 50px;
	}
}

// tab container
.tab-container {
	// margin: 10vh 0 2rem 0;
}

// tab navigation
.tab-navigation {
	display: flex;
	flex-flow: row nowrap;
	list-style: none;
	margin: 0;
	padding: 0;

	@include media($small) {
		flex-flow: row wrap;
	}

	li {
		flex: 1 1 auto;
		text-align: center;

		@include media($small) {
			flex: 1 0 50%;
		}
	}
}

.tab-navigation--item {
	display: block;
	padding: 0 15px 25px 15px;
	border-bottom: 7px solid $light-grey;

	@include media($small) { 
		border-bottom-width: 4px;
		padding: 25px 10px 10px;
	}

	&:hover {
		.tab-icon {
			transform: scale(1.05);
		}
	}

	// make font white if on teal patterned background
	.teal-pattern & {
		color: white;
	}
}

.tab-navigation--title {
	text-transform: uppercase;
	@include font-family--futura-bold;
}

// tab content
.tab-content {
	padding: 85px;
	background-color: white;
  	box-shadow: 0px 9px 35px 0px rgba(16, 38, 99, 0.33);

  	@include media($medium) {
        padding: $gutter*2 $gutter;
      }
      
      @include media($small) {
        padding: $gutter $gutter/2;
  	}
}

.tab-content--item {
	display: none;
	flex-flow: row wrap;
	align-items: center;
	opacity: 0;
	transition: opacity 1s ease;

	.flex {
		flex: 1 1 100%;
	}
}

.tab-image {
	flex: 0 0 30%;
	padding-right: 50px;

	@include media($large) {
		flex: 0 0 50%;
		padding-right: 30px;
	}

	@include media($medium) {
		padding-right: 0;
		flex: 0 0 100%;
		text-align: center;
		margin-bottom: 15px;

		img {
			max-width: 175px;
		}
	}
}

.tab-text {
	flex: 0 0 70%;

	@include media($large) {
		flex: 0 0 50%;
	}

	@include media($medium) {
		flex: 0 0 100%;
	}
}

// active styles
.active {
	&.tab-navigation--item {
		border-bottom-color: $gold;

		.tab-icon {
			transform: scale(1.15);
		}
	}

	&.tab-content--item {
		display: flex;
		opacity: 1;
	}
}
