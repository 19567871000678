// floating images with text on hotels page
.floating-image--container {
	display: flex;
	flex-flow: row wrap;
	position: relative;
	background-image: url('/images/floating image pattern.svg');
	background-size: 125px auto;
	background-repeat: repeat-y;
	background-color: white;
	padding: 5vw;
	margin-left: 5vw;
	margin-right: 5vw;

	@include media(990px) {
		background-repeat: no-repeat;
		background-size: auto 101%;
		align-items: center;
	}

	@include media($medium) {
		background-image: url('/images/floating image pattern_horizontal.svg');
		background-size: 101% auto;
		background-repeat: no-repeat;
		padding: 25px;
		margin: 0;
	}
}

.floating-image__left {
	background-position: 0 0;

	.floating-image {
		margin-bottom: 2rem;
		transform: translateX(-10vw);

		@include media($medium) {
			transform: translate(-50px, -50px);
		}
	}
}

.floating-image__right {
	background-position: 100% 0;
	margin-top: 2rem;

	@include media($medium) {
		background-position: 0 0;
	}

	.floating-image {
		justify-self: flex-end;
		margin-left: auto;
		margin-bottom: 2rem;

		@include media(new-breakpoint(max-width 875px)) {
			order: -1;
		}

		@include media($medium) {
			justify-self: auto;
			margin-left: 0;
			transform: translate(-50px, -50px);
		}
	}
}

.floating-image {
	flex: 0 0 75%;
	position: relative;
	margin-bottom: 2rem;

	@include media(875px) {
		flex: 1 1 40%;
	}

	@include media($medium) {
		margin-bottom: 0;
	}

	img {
		// position: absolute;
		// left: 50%;
		// top: 0;
		min-width: calc(100% + 10vw);
		box-shadow: 0px 9px 35px 0px rgba(16, 38, 99, 0.33);
	}
}

.floating-image--text {
	flex: 1 1 60%;

	.floating-image__left & {
		padding-left: 2rem;
	}

	.floating-image__right & {
		padding-right: 2rem;
	}

	@include media(new-breakpoint(max-width 875px)) {
		flex: 1 1 100%;

		.floating-image__left & {
			padding-left: 0;
		}

		.floating-image__right & {
			padding-right: 0;
		}
	}

	@include media($medium) {
		margin-top: -50px;
		// transform: translateY(-50px);
	}
}