// Breakpoints 
$xlarge-breakpoint			: 1440px !default;
$large-breakpoint			: 1024px !default;
$medium-breakpoint			: 768px	!default;
$small-breakpoint			: 550px !default;
$xsmall-breakpoint			: 0px !default;

$grid-breakpoints: (
  xs: $xsmall-breakpoint,
  sm: $small-breakpoint,
  md: $medium-breakpoint,
  lg: $large-breakpoint,
  xl: $xlarge-breakpoint
) !default;

$menu-link-padding-x		: 3.125rem !default;