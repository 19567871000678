html {
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    background-color: $white;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    @include font-family--karla;
    font-size: $base__font-size;
    line-height: $base__line-height;
    color: $grey;
}

::selection {
    background: $grey;
    color: $white;
    text-shadow: none;
}

::-moz-selection {
    background: $grey;
    color: $white;
    text-shadow: none;
}
