.section {
    position: relative;
    padding: 10vh 0;
}

.section-large {
    position: relative;
    padding: 20vh 0;

    @include media($small) {
        padding: 10vh 0;
    }
}

.section--noPad {
    position: relative;
    padding: 0 !important;
}

.section--smallPad {
    position: relative;
    padding: 50px 0;
}



@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // Margin Top
    .mt#{$infix}-0 {
        @include media(map-get($grid-breakpoints, $breakpoint)) {
            margin-top: 0 !important;
        }
    }

    .mt#{$infix}-1 {
        @include media(map-get($grid-breakpoints, $breakpoint)) {
            margin-top: $base__font-size;
        }
    }

    .mt#{$infix}-2 {
        @include media(map-get($grid-breakpoints, $breakpoint)) {
            margin-top: $base__font-size * 1.5;
        }
    }

    .mt#{$infix}-3 {
        @include media(map-get($grid-breakpoints, $breakpoint)) {
            margin-top: $base__font-size * 2.5;
        }
    }

    .mt#{$infix}-4 {
        @include media(map-get($grid-breakpoints, $breakpoint)) {
            margin-top: $base__font-size * 5;
        }
    }

    .mt#{$infix}-5 {
        @include media(map-get($grid-breakpoints, $breakpoint)) {
            margin-top: $base__font-size * 10;
        }
    }

    // Margin Bottom
    .mb#{$infix}-0 {
        @include media(map-get($grid-breakpoints, $breakpoint)) {
            margin-bottom: 0 !important;
        }
    }

    .mb#{$infix}-1 {
    	@include media(map-get($grid-breakpoints, $breakpoint)) {
    		margin-bottom: $base__font-size;
	    }
    }

    .mb#{$infix}-2 {
    	@include media(map-get($grid-breakpoints, $breakpoint)) {
    		margin-bottom: $base__font-size * 1.5;
	    }
    }

    .mb#{$infix}-3 {
    	@include media(map-get($grid-breakpoints, $breakpoint)) {
    		margin-bottom: $base__font-size * 2.5;
	    }
    }

    .mb#{$infix}-4 {
    	@include media(map-get($grid-breakpoints, $breakpoint)) {
    		margin-bottom: $base__font-size * 5;
	    }
    }

    .mb#{$infix}-5 {
    	@include media(map-get($grid-breakpoints, $breakpoint)) {
    		margin-bottom: $base__font-size * 10;
	    }
    }

    // Padding top
    .pt#{$infix}-0 {
        @include media(map-get($grid-breakpoints, $breakpoint)) {
            padding-top: 0 !important;
        }
    }

    .pt#{$infix}-1 {
        @include media(map-get($grid-breakpoints, $breakpoint)) {
            padding-top: $base__font-size;
        }
    }

    .pt#{$infix}-2 {
        @include media(map-get($grid-breakpoints, $breakpoint)) {
            padding-top: $base__font-size * 1.5;
        }
    }

    .pt#{$infix}-3 {
        @include media(map-get($grid-breakpoints, $breakpoint)) {
            padding-top: $base__font-size * 2.5;
        }
    }

    .pt#{$infix}-4 {
        @include media(map-get($grid-breakpoints, $breakpoint)) {
            padding-top: $base__font-size * 5;
        }
    }

    .pt#{$infix}-5 {
        @include media(map-get($grid-breakpoints, $breakpoint)) {
            padding-top: $base__font-size * 10;
        }
    }

    // Padding bottom
    .pb#{$infix}-0 {
        @include media(map-get($grid-breakpoints, $breakpoint)) {
            padding-bottom: 0 !important;
        }
    }

    .pb#{$infix}-1 {
    	@include media(map-get($grid-breakpoints, $breakpoint)) {
    		padding-bottom: $base__font-size;
	    }
    }

    .pb#{$infix}-2 {
    	@include media(map-get($grid-breakpoints, $breakpoint)) {
    		padding-bottom: $base__font-size * 1.5;
	    }
    }

    .pb#{$infix}-3 {
    	@include media(map-get($grid-breakpoints, $breakpoint)) {
    		padding-bottom: $base__font-size * 2.5;
	    }
    }

    .pb#{$infix}-4 {
    	@include media(map-get($grid-breakpoints, $breakpoint)) {
    		padding-bottom: $base__font-size * 5;
	    }
    }

    .pb#{$infix}-5 {
    	@include media(map-get($grid-breakpoints, $breakpoint)) {
    		padding-bottom: $base__font-size * 10;
	    }
    }
}

//***************************************//
// Text Helpers
//***************************************//
.text-reset {
    font-style     : normal;
    font-weight    : normal;
    text-decoration: none;
    text-transform : none;
}
// Style
.text-italic        { font-style      : italic; }
.text-bold          { font-weight     : $font-weight--bold; }
.text-underline     { text-decoration : underline; }
.text-strike        { text-decoration : line-through; }
.text-capitalize    { text-transform  : capitalize; }
.text-uppercase     { text-transform  : uppercase; }
.text-lowercase     { text-transform  : lowercase; }
.text-none          { text-transform  : none; }
.text-letterSpacing { letter-spacing  : 2px; }

// Alignment
.text-left     { text-align : left; }
.text-right    { text-align : right; }
.text-center   { text-align : center; }




//***************************************//
// Positioning Helpers
//***************************************//
.float-left     { float: left; }
.float-right    { float: right; }
.float-clear    { clear: both; }
.relative       { position: relative; }
.no-margin      { margin: 0 !important; }
.no-padding     { margin: 0 !important; }
.block          { display: block; }
.inline-block   { display: inline-block; }
.inline         { display: inline; }
.show           { display: block !important; }
.hide           { display: none !important; }




// box shadow
.box-shadow {
	background: white;
	box-shadow: 0px 9px 35px 0px rgba(16, 38, 99, 0.33);
}


//unstyled list
.ul-no-style {
	list-style: none;
	margin: 0;
	padding: 0;
}


//hide element except for screenreaders
.sr-only {
    @include sr-only;
}
