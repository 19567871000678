button {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: none;
  padding: 0;

  &:focus {
      outline: none;
  }
}

%btn {
    @include font-family--futura-bold;
    position: relative;
    cursor: pointer;
    padding: 20px 105px 20px 25px;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 3px;
    background-color: $teal;
    color: $white;
    overflow: hidden;

    @include media($medium) {
        letter-spacing: 0;
        padding: 15px 100px 15px 20px;
    }

    &:before, &:after {
        content: "";
        position: absolute;
        width: 75px;
        height: 100%;
        top: 0; right: 0;
        transition: transform 0.3s ease;
    }

    &:before {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMTM0LjU1IDgyLjg5IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMzQuNTUgODIuODk7IGZpbGw6ICNmZmY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggZD0iTTEzMy42Myw0NC40OWMwLjAxLTAuMDIsMC4wMi0wLjA0LDAuMDQtMC4wNmMwLjE4LTAuMjgsMC4zNC0wLjU4LDAuNDctMC44OWMwLjAxLTAuMDIsMC4wMi0wLjA1LDAuMDMtMC4wNwoJYzAuMTItMC4zMSwwLjIyLTAuNjIsMC4yOC0wLjk2YzAtMC4wMiwwLTAuMDQsMC4wMS0wLjA1YzAuMDYtMC4zMywwLjEtMC42NywwLjEtMS4wMXMtMC4wNC0wLjY4LTAuMS0xLjAxYzAtMC4wMiwwLTAuMDQtMC4wMS0wLjA1CgljLTAuMDYtMC4zMy0wLjE2LTAuNjUtMC4yOC0wLjk2Yy0wLjAxLTAuMDItMC4wMi0wLjA1LTAuMDMtMC4wN2MtMC4xMy0wLjMxLTAuMjktMC42MS0wLjQ3LTAuODljLTAuMDEtMC4wMi0wLjAyLTAuMDQtMC4wNC0wLjA2CgljLTAuMi0wLjMxLTAuNDQtMC41OS0wLjctMC44NUw5Ni45OSwxLjYxYy0yLjE1LTIuMTUtNS42My0yLjE1LTcuNzgsMGMtMi4xNSwyLjE1LTIuMTUsNS42MywwLDcuNzhsMjYuNTYsMjYuNTZINS41CgljLTMuMDQsMC01LjUsMi40Ni01LjUsNS41czIuNDYsNS41LDUuNSw1LjVoMTEwLjI3TDg5LjIyLDczLjVjLTIuMTUsMi4xNS0yLjE1LDUuNjMsMCw3Ljc4YzEuMDcsMS4wNywyLjQ4LDEuNjEsMy44OSwxLjYxCglzMi44Mi0wLjU0LDMuODktMS42MWwzNS45NC0zNS45NEMxMzMuMiw0NS4wOCwxMzMuNDMsNDQuOCwxMzMuNjMsNDQuNDl6Ii8+Cjwvc3ZnPgo=);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 30px 20px;

        @include media($medium) {
            background-size: 25px 15px;
        }
    }

    &:after {
        background: transparentize($white, 0.75);
        z-index: 1;
    }

    &:hover {
        color: $white;

        &:after {
            transform: translateX(100%);
        }
    }
}

.btn {
    @extend %btn;

    &Gold {
        @extend %btn;
        background-color: $gold;
    }

    &Navy {
        @extend %btn;
        background-color: $navy;
    }
}

.btnLinkGold {
    @include font-family--futura-bold;
    position: relative;
    cursor: pointer;
    padding: 20px 0;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: $gold;
    overflow: hidden;

    @include media($medium) {
        letter-spacing: 0;
        // padding: 15px 100px 15px 20px;
    }

    &:after {
        content: "";
        position: relative;
        display: inline-block;
        width: 20px; height: 20px;
        top: 4px; right: 0;
        transition: transform 0.3s ease;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxMzQuNiA4Mi45IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMzQuNiA4Mi45OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I0M4QjUyRTt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xMzMuNiw0NC41QzEzMy42LDQ0LjUsMTMzLjcsNDQuNSwxMzMuNiw0NC41YzAuMi0wLjMsMC40LTAuNiwwLjUtMWMwLDAsMCwwLDAtMC4xYzAuMS0wLjMsMC4yLTAuNiwwLjMtMWwwLDAKCWMwLjEtMC4zLDAuMS0wLjcsMC4xLTFzMC0wLjctMC4xLTFsMCwwYy0wLjEtMC4zLTAuMi0wLjctMC4zLTFjMCwwLDAsMCwwLTAuMWMtMC4xLTAuMy0wLjMtMC42LTAuNS0wLjljMCwwLDAsMCwwLTAuMQoJYy0wLjItMC4zLTAuNC0wLjYtMC43LTAuOEw5NywxLjZjLTIuMi0yLjItNS42LTIuMi03LjgsMHMtMi4yLDUuNiwwLDcuOEwxMTUuOCwzNkg1LjVjLTMsMC01LjUsMi41LTUuNSw1LjVTMi41LDQ3LDUuNSw0N2gxMTAuMwoJTDg5LjIsNzMuNWMtMi4yLDIuMi0yLjIsNS42LDAsNy44YzEuMSwxLjEsMi41LDEuNiwzLjksMS42czIuOC0wLjUsMy45LTEuNmwzNS45LTM1LjlDMTMzLjIsNDUuMSwxMzMuNCw0NC44LDEzMy42LDQ0LjV6Ii8+Cjwvc3ZnPgo=);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        margin-left: 10px;
    }

    &:hover {
        color: $gold;

        &:after {
            transform: translate3d(10px, 0, 0);
        }
    }
}
