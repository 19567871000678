.listings-top--container {
	display: flex;
	flex-flow: row nowrap;

	@include media($large) {
		flex-wrap: wrap;
	}

	.listings-filter--buttons {
		flex: 0 0 43.629032%;
		max-width: 43.629032%;

		@include media($large) {
			flex: 0 0 100%;
			max-width: none;
		}
	}

	.listings-top--image-map {
		flex: 0 0 56.370968%;
		max-width: 56.370968%;

		@include media($large) {
			order: -1;
			flex: 0 0 100%;
			max-width: none;
		}

		.mapify-hover {
			fill: rgba($teal, .85);
		}
	}
}


#listings-slider {
	padding: 3rem 5rem;

	@include media($small) {
		padding: 3rem 3rem;
    }
    
    #no-results {
        width: 100%;
        margin-right: 0;
        text-align: center;
        text-transform: uppercase;
        font-size: .875rem;
    }

	// next previous buttons
	.flickity-prev-next-button {
		@include font-family--futura;
		text-transform: uppercase;
		background-color: $teal;
		border-radius: 0;
		color: white;
		width: auto;
		height: auto;
		padding: 1rem 1.5rem;

		@include media($small) {
			padding: .5rem 1rem;
			font-size: .875rem;
		}

		svg {
			display: none;
		}

		&:disabled {
			background-color: transparent;
		}

		&.next:disabled,
		&.previous:disabled {
			&:after {
				content: '';
			}
		}

		&.next {
			transform: rotate(-90deg) translate(75%,-50%);
			transform-origin: right center;
			right: 0;

			&:after {
				content: 'Next';
			}
		}

		&.previous {
			transform: rotate(-90deg) translate(-29%,50%);
			transform-origin: left center;
			left: 0;

			&:after {
				content: 'Previous';
			}
		}
	}
}


.listings-filter--buttons {
	background-color: $gold;
	padding: 2rem;

	h6,
	button {
		color: darken($gold, 15%);
		font-weight: 500;
	}

	h6 {
		font-size: .875rem;
		text-transform: uppercase;
		letter-spacing: .125rem;
	}

	button {
		@include font-family--futura;
		text-align: left;
		display: block;
		padding: .5rem 3rem;
		border-left: 8px solid transparent;
		cursor: pointer;

		@include media($small) {
			padding: .5rem 1.5rem;
		}

		&.active {
			color: white;
			border-left-color: white;
		}
	}
}


.listings-slider--item {
	width: 45%;
	margin-right: 5%;

	@include media($large) {
		width: 95%;
	}

	a {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;

		@include media($small) {
			flex-wrap: wrap;
		}
	}

	.listings-slider--item--icon {
		flex: 0 0 35%;
		max-width: 35%;
		padding-right: 2.5rem;

		@include media($medium) {
			flex: 0 0 45%;
			max-width: 125px;
		}

		@include media($small) {
			flex: 1 1 100%;
			max-width: none;
			margin-bottom: 1rem;

			img {
				width: 75px;
				height: auto;
			}
		}
	}

	.listings-slider--item--info {
		flex: 1 1 auto;

		@include media($small) {
			flex: 1 1 100%;
		}

		h4 {
			margin-bottom: 0;
		}

		.listing-square-feet {
			font-size: .875rem;
		}
	}
}
