.brand {
    display: inline-block;
    width: 250px;
    margin-bottom: 0;

    @include media($medium) {
        width: 200px;
    }

    @include media($small) {
        width: 135px;
    }
}
