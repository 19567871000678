img,
object,
embed {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.fullWidth {
    width: 100%;
    max-width: none;
}

.fullWidthImage {
    position: relative;
    z-index: 1;

    a {
		position: absolute;
		right: 0;
		bottom: 0;
        
        @include media($small) {
            position: relative;
            right: auto;
            bottom: auto;
            width: 100%;
        }
    }

    img {
        width: 100%;
        max-width: none;
    }
}
