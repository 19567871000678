.news-article {
	&--container {
		background-color: white;
		box-shadow: 0px 9px 35px 0px rgba(16, 38, 99, 0.19);
		display: flex;
		flex-flow: row nowrap;
		margin-bottom: 3.25rem;

		@include media($small) {
			flex-wrap: wrap;
		}
	}

	&--sidebar {
		flex: 0 0 24.883937%;
		align-self: stretch;
		padding: 40px;
		background-color: lighten($light-grey-teal, 3%);

		@include media($small) {
			flex: 1 0 100%;
			padding: 25px;
			display: flex;
			flex-flow: row wrap;
		}
	}

	&--icon {
		margin-bottom: 2rem;

		@include media($small) {
			flex: 0 0 100%;
			margin-bottom: .5rem;

			img {
				width: 100px;
			}
		}
	}

	&--main-content {
		flex: 1 1 auto;

		@include media($small) {
			flex: 1 0 100%;
			order: -1;
		}
	}

	&--image-header {
		height: 334px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		@include media($medium) {
			height: 275px;
		}
	}

	&--article-item {
		padding: 50px 50px 80px;
	}

	&--date {
		@include font-family--futura;
		font-weight: 500;
		text-transform: uppercase;
		color: $gold;
		text-align: center;
	}

	&--text {
		width: 100%;
		max-width: 550px;
		margin: 0 auto;

		img {
			display: block;
			width: calc(100% + 50px);
			max-width: none;
			margin: 3rem -25px;
		}
	}
}


.social-share {

	&--share-text {
		text-align: center;
		margin-bottom: 3rem;
		text-transform: uppercase;
		line-height: 1;
		color: darken($light-grey-teal, 30%);

		@include media($small) {
			flex: 1 0 100%;
			margin-bottom: .5rem;
			text-align: left;
		}
	}

	&--facebook-icon,
	&--twitter-icon,
	&--linkedin-icon {
		width: 57px;
		height: 57px;
		padding: .5rem;
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 2rem;
		border-radius: 1000px;
		text-align: center;
		transition: transform .3s cubic-bezier(0.74,-0.49, 0.52, 1.47),
					box-shadow .3s linear;

		@include media($small) {
			display: inline-block;
			margin-bottom: 0;
			margin-left: .25rem;
			margin-right: .25rem;
		}

		&:hover {
			transform: scale(1.1);
			box-shadow: 0 3px 10px rgba(black,.3);
		}

		svg {
			height: 100%;
		}
	}

	&--facebook-icon {
		background-color: $facebook-blue;

		svg {
			width: 14px;
		}
	}

	&--twitter-icon {
		background-color: $twitter-blue;

		svg {
			width: 26px;
		}
	}

	&--linkedin-icon {
		background-color: $linkedin-color;

		svg {
			width: 26px;
		}
	}
}