.maps--container {
	height: 550px;
	display: flex;
	flex-flow: row nowrap;

	@include media($medium) {
		flex-flow: row wrap;
		height: auto;
	}
}

#location-map {
    width: 100%;
    height: 550px;
    flex: 1 1 auto;

	@include media($medium) {
		flex: 0 0 100%;
		height: 300px;
	}
}

// .maps--google-map {
// 	flex: 1 1 auto;

// 	@include media($medium) {
// 		flex: 0 0 100%;
// 		height: 300px;
// 	}
// }


// google maps scrolling list
.maps--list {
	background-color: #d7dce8;
	flex: 0 0 32.884777%;
	height: 100%;

	@include media($medium) {
		flex: 0 0 100%;
	}

	.scrolling-control-up,
	.scrolling-control-down,
	li {
	}

	.scrolling-control-up,
	.scrolling-control-down {

	}

	li {

	}

	li:last-child {

	}

	.scrolling-control-up,
	.scrolling-control-down {
		padding: .5rem 1rem;
		text-align: center;
		width: 100%;
    	height: 2.25rem;
		display: block;
		line-height: 1;
    	position: relative;


	    &:after {
	      content: '';
	      width: 22px;
	      height: 9px;
	      position: absolute;
	      top: 50%;
	      left: 50%;
	      transform: translateY(-50%) translateX(-50%);
	      background-size: 22px 9px;
	      background-position: center;
	      background-repeat: no-repeat;
	    }

		&:hover {
			background-color: rgba($teal, .4);
		}

		svg {
			width: 1.25rem;
		}

		&.disabled {

		    &.scrolling-control-up:after,
		    &.scrolling-control-down:after {
		      	opacity: .25;
		    }

			pointer-events: none;

			&:hover {
				background-color: transparent;
			}

			svg {
				opacity: .15;
			}
		}
	}

  	.scrolling-control-up:after {
    	@include background-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.9 8.3"><path fill="#3c3d37" d="M10 0l9.9 7.5-.6.8L10 1.2.6 8.3 0 7.5z"/></svg>');
  	}

  	.scrolling-control-down:after {
    	@include background-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.9 8.3"><path fill="#3c3d37" d="M10 8.3L0 .8.6 0 10 7l9.3-7 .6.8z"/></svg>');
  	}

	.scrolling-control-down.disabled {
	}

	.scrolling-control-up.disabled {
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		overflow-y: scroll;
		height: calc(100% - 4.5rem);

		@include media($medium) {
			height: 15rem
		}

		&::-webkit-scrollbar-track {
		  	background-color: transparent;
		  	border-radius: 4px;
		}

		&::-webkit-scrollbar {
		  	width: 5px;
		  	background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 4px;
		  	background-color: darken(#d7dce8, 25%);
		}
	}

	li {
		width: 100%;
		@include font-family--futura;
		text-transform: uppercase;
		font-size: .875rem;
		font-weight: 500;
		margin-bottom: 1rem;

		button {
            display: block;
            width: 100%;
            text-align: left;
            @include font-family--futura;
			padding: .5rem 2rem;
            border-left: 5px solid transparent;
            text-transform: uppercase;
            color: $navy;
            cursor: pointer;
            transition: all 0.3s ease;

            &.active, &:hover {
                border-left-color: $teal;
                color: $teal;
            }
		}
	}
}
