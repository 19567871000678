@charset "UTF-8";

$em-base: 16px !default;

// Sets the relative width of a single grid column. The unit used should be the same one used to define `$gutter`. Set with a `!global` flag.
// @type Number (Unit)
$column: 4.2358em !default;

// Sets the relative width of a single grid gutter. The unit used should be the same one used to define `$column`. Set with the `!global` flag.
// @type Number (Unit)
$gutter: 2rem !default;

// Sets the total number of columns in the grid. Its value can be overridden inside a media query using the `media()` mixin. Set with the `!global` flag.
// @type Number (Unitless)
$grid-columns: 12 !default;

// Sets the max-width property of the element that includes `outer-container()`. Set with the `!global` flag.
// @type Number (Unit)
$max-width: 1200px !default;

// Sets the default [media feature](http://www.w3.org/TR/css3-mediaqueries/#media) that `media()` and `new-breakpoint()` revert to when only a breakpoint value is passed. Set with a `!global` flag.
// @type String
$default-feature: min-width; // Default @media feature for the breakpoint() mixin

// Sets the default layout direction of the grid. Can be `LTR` or `RTL`. Set with a `!global` flag.
// @type String
$default-layout-direction: LTR !default;
