.directory-page {

	#hero.page-header {
		.page-header--title {
			flex: 0 0 40%;
		}

		.page-header--subtitle {
			flex: 0 0 60%;
			max-width: 60%;

			@include media($medium) {
				flex: 1 1 100%;
				max-width: none;
			}
		}

		.page-header--icon {
			flex: none;
			position: absolute;
			right: 30px;
			bottom: 0;
			max-width: none;
			height: auto;
			z-index: -1;

			svg {
				width: 320px;
				height: 305px;

				@include media($medium) {
					width: 200px;
					height: (200 * 0.953125) * 1px;
				}
			}
		}
	}
}


// reset margin on h4
button h4 {
	margin-bottom: 0;
	font-size: 1.5rem;

	@include media($small) {
		font-size: 1.15rem;
	}
}

.filter-button {
	padding: 1rem;
	margin-bottom: .25rem;
	margin-right: .25rem;
	cursor: pointer;

	&.is-checked {
		border-bottom: 8px solid $gold;
	}

	@include media($medium) {
		padding: .5rem;
	}
}


.directory-grid--item {
	position: relative;
	width: calc(33.333333% - 1rem);
	margin: .5rem;
	float: left; 
	padding-bottom: (100 / 3) * 1%;
	z-index: 1;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	box-shadow: 0px 0px 0px rgba(16, 38, 99, 0.33);
	transform: translate3d(0,0,0);
	transition: box-shadow .3s linear,
				margin .3s linear;

	@include media($large) {
		width: calc(50% - 1rem);
		padding-bottom: 50%;
	}

	@include media($small) {
		width: calc(100% - 1rem);
		padding-bottom: 100%;
	}

	.directory-grid--innner {
		position: absolute;
		overflow: hidden;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		padding: 1rem;
		display: flex;
		flex-flow: row wrap;
		background: rgba(#001019, .7);
		transition: background-color .3s ease-in-out;
	}

	.directory-grid--name,
	.directory-popup--link {
		flex: 0 0 100%;
	}

	.directory-grid--name {
		@include font-family--futura;
		align-self: center;
		font-weight: 500;
		color: white;
		font-size: 2.25rem;
		line-height: 1;
		text-align: center;
		overflow: hidden;
		max-height: 50%;

		@include media(new-breakpoint(max-width 876px)) {
			font-size: 1.75rem;
		}
	}

	.directory-popup--link {
		align-self: flex-end;
		position: absolute;
		left: 1rem;
		right: 1rem;
		display: block;
		@include font-family--futura;
		font-size: .875rem;
		font-weight: bold;
		text-transform: uppercase;
		letter-spacing: .125rem;
		padding: .5rem 1rem;
		background-color: $gold;
		color: white;
		text-align: center;
		transform: scale(1.5);
		opacity: 0;
		transition: all .3s cubic-bezier(0.74,-0.49, 0.52, 1.47);
	}

	&:hover {
		box-shadow: 0px 9px 35px 0px rgba(16, 38, 99, 0.33);
		margin-top: -.25rem;

		.directory-grid--innner {
			background: rgba(#001019, 0);
		}

		.directory-popup--link {
			transform: scale(1);
			opacity: 1;
		}
	}

	// Hide popup content if it's inside directory-grid--item
	.directory-popup--content {
		display: none;
		visibility: hidden;
		height: 0;
		width: 0;
		clip: rect(0px,0px,0px,0px);
	}
}


// directory popup
.directory-popup {
	display: none;
	visibility: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1001;
	overflow-y: auto;
}

.directory-popup--container {
	width: 100%;
	max-width: 682px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 2rem;
	margin-bottom: 5rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.directory-popup--header {
	padding: 1.5rem 1rem 0 1rem;
	border-top: 10px solid $gold;
	background-color: white;
	display: flex;
	justify-content: flex-end;
}

.directory-popup--close {
	flex: 0 1 auto;
	@include font-family--futura;
	color: $gold;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: .5rem;
	display: inline-block;
	padding: .75rem;

	.icon-x {
		margin-right: 8px;
		margin-bottom: -1px;
		width: 14px;
		height: 14px;
		fill: $gold;
		transition: all 0.3s linear;
	}

	&:hover {
		.icon-x {
			transform: rotate(180deg);
			fill: $navy;
		}
	}
}

.directory-popup--body {
	padding: 1rem 5rem 5rem;
	background-color: white;

	@include media($small) {
		padding: 1rem 2rem 2rem;
	}

	h4 {
		font-size: 2.25rem;
		text-transform: capitalize;
		font-weight: 500;
		margin-bottom: 0;
	}

	h6 {
		font-size: .875rem;
		color: $gold;
		text-transform: uppercase;
		margin-top: 2rem;
		margin-bottom: .5rem;
	}

	p {
		margin-bottom: .5rem;
	}
}

.directory-popup--footer {
	display: flex;
	flex-flow: row nowrap;

	@include media($small) {
		flex-flow: row wrap;
	}

	&--address {
		flex: 0 0 41.348974%;
		display: flex;
		align-items: center;
		padding: 3rem 1rem 3rem 5rem;
		background-color: $teal;
		color: white;
		position: relative;
		@include font-family--futura;
		line-height: 1;

		@include media($small) {
			flex: 1 1 100%;
		}

		p {
			margin-bottom: 0;
		}

		.location-address {
			display: block;
			flex: 1 1 auto;
		}

		.location-address-icon {
			display: block;
			position: absolute;
			left: 1.5rem;
			top: 50%;
			transform: translateY(-50%);

			svg {
				width: 2rem;
				height: auto
			}
		}

		.popup-address-number {
			font-size: 2rem;
			font-weight: 500;
			margin-bottom: .5rem;
		}

		.popup-address-building {
			font-size: .875rem;
			letter-spacing: .125rem;
			font-weight: bold;
			text-transform: uppercase;
		}
	}

	&--location-image {
		flex: 0 0 58.651026%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;

		.location-image-padding {
			padding-bottom: 59.75%;
		}

		@include media($small) {
			flex: 1 1 100%;
		}
	}
}

.directory-popup__is-open {
	@at-root body#{&} {
		overflow: hidden;
	}

	.directory-popup {
		display: block;
		visibility: visible;
	}
}

.directory-popup--overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	background-color: $navy;
	opacity: .77;
}

