#intro {
    position: relative;
    z-index: 1;

    .flex {
        padding-right: 400px;
        
        > div {
            flex: 1;

            @include media($small) {
                width: 100%;
                flex: none;
            }
        }

        @include media($large) {
            padding-right: 0;
        }
    }

    .cta {
        display: flex;
        position: absolute;
        top: 0; right: 0;
        width: 350px;
        flex-wrap: wrap;

        @include media($large) {
            position: relative;
            // flex-wrap: nowrap;
            width: calc(100% + #{$gutter*2});
            margin-right: -$gutter;
            margin-left: -$gutter;
        }

        @include media($small) {
            // flex-wrap: wrap;
            width: calc(100% + #{$gutter});
            margin-right: -$gutter/2;
            margin-left: -$gutter/2;
        }

        a {
            color: $white;
            padding: 50px;
            display: block;
            width: 100%;
            background-color: $gold;

            @include media($large) {
                flex: 1;
                padding: 25px;
            }

            @include media($medium) {
                flex: none;
                width: 100%;
            }

            h3 {
                color: $white;
            }

            &:last-child {
                background-color: shade($gold, 10%);

                h3 {
                    margin-bottom: 0;
                }

                svg {
                    width: 50px; height: 50px;
                    margin-left: 0;
                }

                @include media($large) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;

                    svg {
                        display: block;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }

                @include media($medium) {
                    display: block;
                    text-align: left;

                    svg {
                        left: auto;
                        transform: none;
                        display: inline-block;
                    }
                }
            }
        }

        h3 {
            @include font-family--futura-bold;
            text-transform: uppercase;
            margin-bottom: 0.5em;

            svg {
                position: relative;
                top: -3px;
                fill: $white;
                width: 20px; height: 20px;
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
            }
        }
    }
}
