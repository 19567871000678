////////////////////////////////
//Fade In
////////////////////////////////
@keyframes clouds {
    100% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-2200px, 0, 0);
    }
}

////////////////////////////////
//Spin
////////////////////////////////
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.spin {
    animation: spin 0.5s linear infinite;
}
