header[role=banner] {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    padding: 40px 80px;
    z-index: 2;
    overflow: hidden;

    @include media($medium) {
    	padding: 25px;
    }
}

//Menu toggle
#menu-toggle {
	@include font-family--futura-bold;
	color: $gold;
	float: right;
	font-size: 1.25rem;
	letter-spacing: .35rem;
	z-index: 100;
    position: relative;
    top: 10px;

    @include media($medium) {
    	top: 5px;
    }

    @include media($small) {
        letter-spacing: 0;
        top: 7px;
    }

	[class^="toggle-letter-"] {
		transition: opacity .5s ease-in-out;
	}

	.toggle-letter-c,
	.toggle-letter-l,
	.toggle-letter-s,
	.toggle-letter-e {
		opacity: 0;
		color: white;
		display: none;
	}

	.hamburger {
		display: inline-block;
		width: 15px;
		height: 13px;
		margin-left: 3px;
		margin-right: 8px;
        position: relative;
        
        @include media($small) {
            margin-right: 3px;
        }

		& > span {
			display: block;
			height: 3px;
			width: 100%;
			background-color: $gold;
			margin-bottom: 2px;
			transition: transform ease-in-out .3s;
		}

		& > span:nth-child(3) {
			margin-bottom: 0;
		}
	}

	.hamburger-x {
		position: absolute;
		width: 150%;
		height: 150%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		overflow: hidden;

		span {
			background-color: $gold;
			display: block;
			height: 3px;
			width: 19px;
			position: absolute;
			left: 50%;
			top: 50%;
			margin-top: -1.5px;
			margin-left: -9.5px;
			transition: transform ease .5s;
		}

		span:first-child {
			transform: rotate(-45deg) translate3d(150%, 150%, 0);
		}

		span:last-child {
			transform: rotate(45deg) translate3d(150%, 150%, 0);
		}
		
	}

	&:hover {
		.hamburger { 
			& > span:first-child {
				transform: translate3d(0, -4px, 0);
			}

			& > span:nth-child(3) {
				transform: translate3d(0, 4px, 0);
			}
		}
	}
}

.menu-open#menu-toggle {

	.toggle-letter-m,
	.toggle-letter-n,
	.toggle-letter-u {
		opacity: 0;
	}

	.hamburger {
		& > span:first-child {
			animation: hamburger-one .5s linear ;
			animation-fill-mode: forwards;
		}

		& > span:nth-child(2) {
			opacity: 0;
		}

		& > span:nth-child(3) {
			animation: hamburger-three .5s linear ;
			animation-fill-mode: forwards;
		}
	}

	.hamburger-x {
		span:first-child {
			transform: rotate(-45deg) translate3d(0, 0, 0);
		}

		span:last-child {
			transform: rotate(45deg) translate3d(0, 0, 0);
		}
	}
}

@keyframes hamburger-one {
	0% { transform: translate3d(0, -4px, 0); opacity: 1;}
	100% { transform: translate3d(-300%, -4px, 0); opacity: 0;}
}

@keyframes hamburger-three {
	0% { transform: translate3d(0, 4px, 0); opacity: 1;}
	100% { transform: translate3d(300%, 4px, 0); opacity: 0;}
}

body.menu-open {
	overflow: hidden;
	// padding-right: 15px;

	header[role=banner] {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

//menu overlay
.menu-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	background-color: white;
    opacity: .75;
    transition: opacity .5s cubic-bezier(.81,.01,.51,.98);
}

//Menu drawer
.menu-drawer {
	background-color: $navy;
	color: white;
	height: 100vh;
	width: 450px;
	position: absolute;
	right: 0;
	top: 0;
	padding-top: 125px;
	z-index: 99;
	overflow-x: hidden;
	overflow-y: auto;
	transform: translateX(105%);
	transition: transform .5s cubic-bezier(.81,.01,.51,.98);

	@include media($small) {
		width: 100%;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.nav-item {
		color: white;
		display: inline-block;
		padding: .75rem $menu-link-padding-x;
		position: relative;
	}

	.nav-item--header,
	.nav-item--description {
		@include font-family--futura;
	}

	.nav-item--header {
		display: inline-block;
		font-size: 3rem;
		line-height: 1;
		font-weight: bold;
		text-transform: lowercase;
		position: relative;

		&::after {
			content: '';
			height: 40px;
			width: calc($menu-link-padding-x + 100%);
			margin-left: -$menu-link-padding-x;
			background-color: darken($navy, 5%);
			position: absolute;
			left: 0;
			right: 0;
			z-index: -1;
			transform: translate3d(-100%, 0, 0);
			transition: transform .3s ease-in-out;
		}
	}

	.nav-item--description {
		font-size: .8125rem;
		text-transform: uppercase;
		letter-spacing: .125rem;
		color: rgba(white, .25);
	}

	.nav-item:hover,
	.current.nav-item {

		.nav-item--header::after {
			transform: translate3d(0, 0, 0);
		}

		.nav-item--description {
			color: $gold;
		}

	}

	&.open {
		transform: translateX(0);
	}
}
