.floating-icon {
    position: absolute;
    width: 150px;

    svg {
        width: 100%;
        height: 100%;
    }

    @include media($small) {
        width: 100px;
    }
}

.parallax1 {
    .home-page & {
        top: 20%;
        left: 20px;
    }

    .about-page & {
        top: 0;
        right: 20px;
    }

    .leasing-page & {
        top: 50%;
        left: 20px;
    }
}

.parallax2 {
    .home-page & {
        top: 40%;
        right: 20px;
    }

    .leasing-page & {
        top: 0;
        right: 20px;
    }
}
