//Colors
$white: #fff;
$black: #000;
$teal : #00b0ac;
$gold : #c8b52e;
$navy : #003854;
$grey : #5c5d60;
$medium-grey: #a6a5a5;
$light-grey: #e0e1e2;
$light-grey-teal: #e8ecf4;

//social colors
$facebook-blue: #00334e;
$twitter-blue: $teal;
$linkedin-color: $gold;

//Color Classes
.white {
    color: $white;
}

.black {
    color: $black;
}

.teal {
    color: $teal;
}

.gold {
    color: $gold;
}

.navy {
    color: $navy;
}

.grey {
    color: $grey;
}

//BG Color Classes
.bgWhite {
    background-color: $white;
}

.bgBlack {
    background-color: $black;
}

.bgTeal {
    background-color: $teal;
}

.bgGold {
    background-color: $gold;
}

.bgNavy {
    background-color: $navy;
}

.bgGrey {
    background-color: $grey;
}

.bgFaded {
    background-color: $light-grey-teal;
}

.bgFiftyFifty--light-teal {
    background: linear-gradient(to bottom, white 50%, $light-grey-teal 50%);
}

.bgEightyTwenty--light-teal {
    background: linear-gradient(to bottom, white 20%, $light-grey-teal 20%);
}
