.features {

	& > div {
		display: flex;
		flex-flow: column;
	}

	a[class^='btn'] {
		margin-top: auto;
	}
}
