//Font Size
$base__font-size   : 1.125rem;
$base__line-height : 1.625;

//Font Weights
$font-weight--light   : 300;
$font-weight--regular : 400;
$font-weight--bold    : 700;

//Font Mixins
@mixin font-family--futura {
    font-family: 'futura-pt', Helvetica Neue, Helvetica, Arial, sans-serif;
}

@mixin font-family--futura-bold {
    @include font-family--futura;
    font-weight: bold;
}

@mixin font-family--karla {
    font-family: 'karla', Helvetica Neue, Helvetica, Arial, sans-serif;
}

//Heading Tags
h1, h2, h3, h4, h5, h6 {
    @include font-family--futura;
    color: $navy;
    margin: 0;
    line-height: 1.25;
    margin-bottom: 1rem;
    text-transform: lowercase;
}

h3 {
  color: $teal;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: .125rem;
}


//Paragraph Tag
p {
  margin: 0 0 1em 0;

  &:last-of-type {
    margin-bottom: 0;
  }

  &+h1, &+h2, &+h3, &+h4, &+h5, &+h6 {
    //   margin-top: 1.5em;
  }
}

//Base Links
a {
  color: $navy;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: shade($navy, 10%);
  }
  &:link {
    -webkit-tap-highlight-color: $navy;
  }
}

//Fonts Classes
// .fontPoppins  { @include font-family--poppins; }
// .fontLora     { @include font-family--lora; }
// .fontTermina  { @include font-family--termina; }

.f48 {
    font-size: $f48;

    @include media($medium) {
        font-size: $f48/1.25;
    }
 }

.f24 {
    font-size: $f24;
 }

.f20 {
    font-size: $f20;
 }

.f18 {
    font-size: $f18;
 }


//Secondary font
.futura-pt {
  @include font-family--futura;
}

.error {
    color: #a94442;
    padding: 20px;
    background-color: #f2dede;
    border-color: #ebccd1;
    font-size: 12px;
    list-style: inside;
}

.textDivide {
    margin-bottom: 3rem !important;
    padding-bottom: 3rem !important;
    position: relative;

    &:after {
        content: "";
        width: 100px; height: 4px;
        display: block;
        position: absolute;
        left: 0; bottom: 0;
        background-color: $gold;
    }

    &.center:after {
        left: 50%;
        transform: translateX(-50%);
    }
}

h1.list-header,
h2.list-header,
h3.list-header,
h4.list-header,
h5.list-header,
h6.list-header {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  position: relative;
  @include font-family--karla;
  text-transform: none;
  letter-spacing: 0;
  font-weight: bold;
  color: $grey;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    background-color: $light-grey;
  }
}
