#contactForm {
    background: url('images/pattern.gif') center bottom repeat-x $teal;
    background-size: 900px auto;
    box-shadow: inset 0 50px 50px -50px transparentize($navy, 0.5);

    
    .textDivide:after {
        background-color: transparentize($navy, 0.75)
    }

    > div {
        margin-bottom: 50px;
    }

    .form {
        background-color: $white;
        padding: $gutter*4;
        box-shadow: 0 10px 50px transparentize($navy, 0.5);

        .flex > div {
            flex: 1;
        }

        @include media($medium) {
            padding: $gutter*2;

            .flex > div {
                margin-bottom: 50px;
                flex: none;
            }
        }

        @include media($small) {
            padding: $gutter*2 $gutter/2;
        }
    }
}

form {
    transition: opacity .3s ease;
    text-transform: uppercase;
    text-align: center;

    .success & {
        visibility: hidden;
        opacity: 0;
    }

    .field {
        position: relative;
        margin-bottom: 50px;

        &:last-child {
            margin-bottom: 0;
        }

        &.required label:after {
            content: ' *';
            color: red;
        }

        &.dropdown label {
            display: none;
        }
    }

    input[type=text], input[type=email], select, textarea {
        // @include font-family--poppins;
        background-color: transparent;
        border: none;
        border-bottom: 2px solid transparentize($grey, 0.75);
        border-radius: 0;
        color: $navy;
        width: 100%;
        display: block;
        padding: 10px 0;
        font-size: 16px;

        &:focus {
            outline: none;
            border-bottom-color: $navy;
        }
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0;
        padding: 15px;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8'%3E%3Cpath fill-rule='evenodd' fill='%23C09A5E' d='M0 0h14L7 8 0 0z'/%3E%3C/svg%3E");
        background-position: right .75rem center;
        background-repeat: no-repeat;
        // font-size: $f4;
    }

    #formSubmit {
        @extend %btn;
        background-color: $gold;
        margin-top: 50px;
        transition: background-color 0.3s ease;

        &.clicked {
            background-color: tint($grey, 50%);
            cursor: wait;

            &:before {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCiAgdmlld0JveD0iMCAwIDEwMCAxMDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDAgMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CiAgICA8cGF0aCBmaWxsPSIjZmZmIiBkPSJNNzMsNTBjMC0xMi43LTEwLjMtMjMtMjMtMjNTMjcsMzcuMywyNyw1MCBNMzAuOSw1MGMwLTEwLjUsOC41LTE5LjEsMTkuMS0xOS4xUzY5LjEsMzkuNSw2OS4xLDUwIj4KICAgICAgPGFuaW1hdGVUcmFuc2Zvcm0gCiAgICAgICAgIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgCiAgICAgICAgIGF0dHJpYnV0ZVR5cGU9IlhNTCIgCiAgICAgICAgIHR5cGU9InJvdGF0ZSIKICAgICAgICAgZHVyPSIxcyIgCiAgICAgICAgIGZyb209IjAgNTAgNTAiCiAgICAgICAgIHRvPSIzNjAgNTAgNTAiIAogICAgICAgICByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4KICA8L3BhdGg+Cjwvc3ZnPgo=");
                background-size: 60px 60px;
            }

            &:after {
                transform: translateX(100%);
            }
        }
    }

    label {
        color: transparentize($grey, 0.75);
        cursor: text;
        position: absolute;
        top: 5px; left: 0;
        font-size: $f18;
        font-weight: $font-weight--bold;
        letter-spacing: 3px;
    }
}

#contactForm {
    position: relative;
}

#formErrors {
    display: none;

    .success & {
        display: none !important;
    }
}

#formSuccess {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 50%; left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    width: 100%;
    transition: opacity .3s ease;

    .success & {
        visibility: visible;
        opacity: 1;
    }
}

.placeholder-focus label {
    background-color: $navy !important;
    box-shadow: 0 5px 10px transparentize($navy,0.75) !important;
    bottom: auto !important;
    color: $white !important;
    right: auto !important;
    top: -40px !important;
    padding: 10px !important;
    z-index: 2;
    font-size: 12px;
    letter-spacing: 0;
}

.no-rgba .placeholder-focus label {
    background-color: $black !important;
}

.placeholder-focus label:before {
    bottom: -5px !important;
    content: '' !important;
    display: block !important;
    height: 5px !important;
    width: 5px !important;
    left: 0px !important;
    position: absolute !important;
    background: linear-gradient(315deg, transparent 50%, $navy 50%);
}

.no-rgba .placeholder-focus label:before {
    border-top-color: $navy !important;
}

.placeholder-hide label {
    bottom: auto !important;
    left: -9999px !important;
    right: auto !important;
    top: -9999px !important;
}
