#hero {
    @include gradient-background(to bottom, transparentize($white, 0.4), $white);
    height: 100vh;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    // z-index: -1;

    .home-page & {
        @include media($medium) {
            text-align: center;
        }

        h2 {
            @include media($medium) {
                text-align: center;
            }
        }
    }

    h2 {
        font-size: 15vw;
        text-transform: lowercase;
        letter-spacing: 5px;
        margin-bottom: 0;
        text-align: center;

        @include media($medium) {
            text-align: left;
        }

        @include media($small) {
            font-size: 25vw;
            line-height: 0.9;
        }
    }

    .address {
        position: absolute;
        bottom: 0; left: 80px;
        transform: rotate(-90deg);
        transform-origin: 0 0;
        letter-spacing: 3px;
        color: tint($grey, 50%);
        font-size: $f14;

        @include media($medium) {
            transform: translateX(-50%);
            left: 50%;
            bottom: 50px;
            width: 100%;
        }
    }

    &.page-header {
        flex-flow: row wrap;
        height: 60vh;
        min-height: 350px;
        max-height: 500px;
        max-width: 1280px;
        padding: 150px 30px 0 30px;
        margin: auto;
        // overflow: hidden;
        position: relative;

        // Remove background gardient
        &:after {
            content: none;
        }

        @include media($medium) {
            display: block;
            height: auto;
            padding-bottom: 10vw;
        }

        .page-header--title,
        .page-header--subtitle {
            padding-top: 30px;
            padding-bottom: 30px;

            @include media($medium) {
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }


        .page-header--title {
            padding-right: 50px;
            flex: 0 1 0%;
            max-width: 40%;
            text-align: right;
            position: relative;

            .page-404 & {
                flex: 1;
            }

            &:after {
                content: '';
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate3d(0, -50%, 0);
                height: 100%;
                width: 1px;
                background-color: rgba(black, .1);
            }

            @include media($medium) {
                max-width: 80%;
                text-align: left;
                padding-right: 0;

                &:after {
                    width: 100%;
                    height: 1px;
                    left: 0;
                    bottom: 0;
                    top: auto;
                }
            }

            h1 {
                font-size: 5.25rem;
                margin-bottom: 0;
                color: $gold;

                @include media($medium) {
                    font-size: 3.75rem;
                }
            }
        }

        .page-header--subtitle {
            padding-left: 50px;
            flex: 0 0 40%;
            max-width: 40%;
            text-align: left;

            .page-404 & {
                flex: 1;
            }

            h2 {
                font-size: 2.25rem;
                letter-spacing: 0;
                font-weight: normal;
                margin-bottom: 0;
                color: lighten($grey, 18%);
                text-align: left;
            }

            @include media($medium) {
                max-width: 80%;
                padding-left: 0;

                h2 {
                    font-size: 1.5rem;
                }
            }
        }

        .page-header--icon {
            flex: 0 0 20%;
            max-width: 20%;
            // justify-self: flex-end;
            // align-self: flex-end;
            text-align: center;
            margin-left: auto;
            z-index: -1;

            svg {
                width: 100%;
                height: auto;
            }

            @include media($medium) {
                max-width: 100px;
                width: 100%;
                height: auto;
                text-align: right;
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: -1;
            }
        }
    }
}
