.pagination {
	@include font-family--futura;
	color: $navy;
	font-size: 1.5rem;
	font-weight: 500;
	display: flex;
	flex-flow: row wrap;
	align-items: center;

	ul {
		flex: 1 1 auto;
		max-width: 500px;
		list-style: none;
		margin: 0;
		padding: 0 1rem;
		display: flex;
		flex-flow: row wrap;
		justify-self: center;
		justify-content: center;
		margin-left: auto;
    }
    
    p {
        opacity: 0.25;
    }

	li {

		a {
			display: block;
			padding: .5rem;
			margin: .5rem;
			border-bottom: 6px solid transparent;
		}

		&.current {
			a {
				border-bottom-color: $gold; 
			}
		}
	}

	.prev-link,
	.next-link {
		position: relative;
		display: block;
		flex: 0 0 0%;

		&:before,
		&:after {
			position: absolute;
			width: 30px;
			height: 100%;
			background-position: center;
	        background-repeat: no-repeat;
	        top: 0;
		}
	}

	.prev-link {
		padding-left: 40px;

		&:before {
			content: '';
			left: 0;
			@include background-svg('<svg viewBox="0 0 512 512"><path d="M5 244v1c-1 1-1 2-2 3 0 1-1 3-1 4s-1 3-1 4 1 3 1 4 1 2 1 4c1 1 1 2 2 3v1l3 3 136 136c8 8 22 8 30 0s8-21 0-29L73 277h419c11 0 20-9 20-21s-9-21-20-21H73l101-101c8-8 8-21 0-29-4-4-10-6-15-6s-11 2-15 6L8 241l-3 3z" style="fill:#003854"/></svg>');
			
		}
	}

	.next-link {
		padding-right: 40px;
		margin-left: auto;
		justify-self: end;

		&:before {
			content: '';
			right: 0;
			@include background-svg('<svg viewBox="0 0 512 512"><path d="m508 268c0-1 0-1 0-1 1-1 1-2 2-3 0 0 0 0 0 0 0-1 1-3 1-4 0 0 0 0 0 0 0-1 1-3 1-4 0-1-1-3-1-4 0 0 0 0 0 0 0-1-1-2-1-4 0 0 0 0 0 0-1-1-1-2-2-3 0 0 0 0 0-1-1-1-2-2-3-3l-136-136c-8-8-22-8-30 0-8 8-8 21 0 29l101 101-419 0c-11 0-20 9-20 21 0 12 9 21 20 21l419 0-101 101c-8 8-8 21 0 29 4 4 10 6 15 6 5 0 11-2 15-6l136-136c1-1 2-2 3-3z" style="fill:#003854"/></svg>');
			
		}
	}
}
