footer {
    a {
        width: auto;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .info {
        @include media($medium) {
            .flex {
                > div {
                    text-align: center;
                    margin-bottom: 10vh;

                    &:first-child {
                        order: 2;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .buttons {
            a {
                display: inline-block;
            }
        }
    }

    .credit {
        border-top: 1px solid transparentize($grey, 0.75);
        padding: 25px 0;
        font-size: 14px;
        text-transform: uppercase;

        .flex {
            justify-content: flex-end;

            > div {
                margin-bottom: 0;
                flex: none;

                &:first-of-type {
                    flex: 1;
                }

                @include media($medium) {
                    width: auto;
                    margin-bottom: 0;
                }

                @include media($small) {
                    width: 100%;
                    text-align: center;
                    flex: none !important;
                    margin-bottom: $gutter;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            @include media($medium) {
                justify-content: center;
                flex-wrap: nowrap;
            }

            @include media($small) {
                flex-wrap: wrap;
            }
        }

        a {
            opacity: 0.25;

            &:hover {
                opacity: 1;
            }
        }
    }

    .copyright {
        @include font-family--futura;
        text-transform: uppercase;
        font-size: .8125rem;
        letter-spacing: .125rem;
        justify-self: flex-start;
        margin-right: auto;
    }
}

#paradigmLink {
    margin-left: 25px;

    @include media($small) {
        margin-left: 0;
    }

    svg {
        fill: $grey;
        width: 35px;
        height: 35px;
        display: inline-block;
        vertical-align: middle;
    }

    &:hover svg {
        fill: #a2cd3a;
    }
}
