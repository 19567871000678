%wrap {
    padding: 0 $gutter;
    position: relative;
    margin: {
        left: auto;
        right: auto;
    }

    &::after {
        clear: both;
        content: "";
        display: block;
    }

    @include media($small) {
        padding: 0 $gutter/2;
    }

}

.wrapWide {
    @extend %wrap;
    max-width: 1280px;
}

.wrap {
    @extend %wrap;
    max-width: 980px;
}

.wrapNarrow {
    @extend %wrap;
    max-width: 680px;
}
