// white notch section
.white-notch {
	position: relative;

	&:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        border-top: 75px solid $white;
        border-left: 75px solid transparent;
        border-right: 75px solid transparent;
        transform: translateX(-50%);

        @include media($medium) {
            border-top: 50px solid $white;
            border-left: 50px solid transparent;
            border-right: 50px solid transparent;
        }
    }
}

// pattern and image full width section
.pattern-and-image-section {
	height: 350px;
	width: 100%;
	background: url('images/pattern.gif') repeat left top;
    background-size: 800px auto;
    background-attachment: fixed;

	.right-content {
		width: 67.619048%;
		margin-left: 32.380952%;
		padding: 20px 20px 20px 15vw;
		height: 100%;
		display: flex;
		align-items: center;
		background-color: rgba($navy, .95);
        color: white;
        
        @include media($medium) {
            padding-left: 10vw;
        }

		h2 {
			font-size: 5.3125rem;
			color: white;
			line-height: 1;
		}

		.icon-btnarrow {
			position: relative;
			top: 5px;
			fill: white;
			width: 60px;
			height: 60px;
			transition: transform .3s ease-in-out;

			@include media($small) {
				width: 45px;
				height: 45px;
			}
		}

		a {
			flex: 1 100%;
			color: white;
			@include font-family--futura;

			span {
				letter-spacing: .125rem;
			}

			&:hover {
				.icon-btnarrow {
					transform: translate3d(10px, 0 ,0);
				}
			}
		}
		
		@include media($medium) {
			width: 100%;
			margin-left: 0;
		}

		@include media($small) {
			h2 {
				font-size: 3.75rem;
			}
		}
	}

	&.homepage {
		height: 500px;

		.right-content {
			background-image: url('/images/man-holding-tablet.jpg');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
		}

		@include media($large) {
			height: 400px;
		}
	}
}



// teal pattern
.teal-pattern {
	background-color: $teal;
	background-image: url('images/round_pattern.svg');
	background-position: center;
    background-size: 170px auto;
    background-attachment: fixed;
}
